module controllers {
    export module master {
        interface IRefundSettingUpdateCtrlScope extends ng.IScope {
        }

        interface IRefundSettingUpdateParams extends ng.ui.IStateParamsService {
            refId: number;
        }

        export class refundSettingUpdateCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'refundSettingService',
                'countryService',
                '$state',
                '$stateParams',
                "uiGridConstants",
                "documentTypeService"
            ];

            refId: number = 0;
            refundSetting: interfaces.master.IRefundSetting;
            breadCrumbDesc: string;

            gridConsigneeApi: uiGrid.IGridApi;

            selectedTab: number = 0;
            documentRepository: interfaces.documentManagement.IDocumentRepository;

            gridApi: uiGrid.IGridApi;
            gridDocumentsApi: uiGrid.IGridApi;
            gridQuotaApi: uiGrid.IGridApi;
            gridProductsApi: uiGrid.IGridApi;
            gridQuotaAllocationApi: uiGrid.IGridApi;

            public permStatus: interfaces.master.IStatusData;

            constructor(private $scope: IRefundSettingUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private refundSettingService: interfaces.master.IRefundSettingService,
                private countryService: interfaces.applicationcore.ICountryService,
                private $state: ng.ui.IStateService,
                private $stateParams: IRefundSettingUpdateParams,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private documentTypeService: interfaces.documentManagement.IDocumentTypeService
            ) {
                if ($stateParams.refId == null) {
                    return;
                }
                this.refId = $stateParams.refId;
                this.loadRefundSetting();
            }

            loadRefundSetting() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'refundSetting.update'
                },
                    () => {
                        return this.refundSettingService.getRefundSettingDetail().get({
                            refId: this.refId,
                        }, (result: interfaces.master.IRefundSetting) => {
                            this.refundSetting = result;
                            this.breadCrumbDesc = this.refundSetting.Entity.Code + '-' + this.refundSetting.DeclarationCountry.Code + '-' + this.refundSetting.RefundClaim.Code;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            public registerDocumentsGridApi(gridApi: uiGrid.IGridApi) {
                this.gridDocumentsApi = gridApi;

                this.$timeout(() => {
                    this.gvwDocuments.data = this.refundSetting.RefundSettingDocumentList;
                });
            }


            //Consignee
            public gvwDocuments: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterExcelFilename: 'PermitConsigneeList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD'],
                onRegisterApi: (gridApi) => { this.registerDocumentsGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 56,
                    cellTemplate: `
                    <div class="GridButton" ng-if="row.entity.Id === 0"> 
                            <button type="button"  ng-click="grid.appScope.refundSettingUpdateCtrl.DeleteDocument(row.entity)" class="btn btn-warning btn-sm" >
                                Delete
                            </button>
                    </div>`,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.refundSettingUpdateCtrl.addInlineDocument()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                        name: "Description",
                        displayName: "Description",
                        field: "DocumentDescription",
                        width: 750,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        enableCellEditOnFocus: true,
                        enableCellEdit: false,
                    }, {
                    name: "DocumentType",
                        displayName: "* Document Type",
                        field: "DocumentType",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="Consignee-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.refundSettingUpdateCtrl.loadDocumentTypes()"></gts-dropdown>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    }, {
                        name: "Mandatory",
                        displayName: "Mandatory",
                        field: "IsMandatory",
                        width: 100,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.refundSettingUpdateCtrl.setRowDirtyDocument(row.entity)">',
                    }, {
                        name: "ConsignmentLevel",
                        displayName: "Consignment",
                        field: "IsConsignmentLevel",
                        width: 100,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.refundSettingUpdateCtrl.setRowDirtyDocument(row.entity)">',
                    }, {
                        name: "DA63ImportDocument",
                        displayName: "DA63 Import",
                        field: "IsDA63ImportDocument",
                        width: 100,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.refundSettingUpdateCtrl.setRowDirtyDocument(row.entity)">',
                        visible: `(grid.appScope.refundSettingUpdateCtrl.refundSetting.DeclarationCountry.Id === 249)`,
                    }, {
                    name: "Active",
                    displayName: "Active",
                    field: "IsActive",
                    width: 100,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.refundSettingUpdateCtrl.setRowDirtyDocument(row.entity)">',
                }]
            };


            addInlineDocument() {
                var conItem: interfaces.master.IRefundSettingDocument = {
                    DDSettingDocumentId: 0,
                    IsConsignmentLevel: false,
                    IsDA63ImportDocument: false,
                    IsMandatory: false,
                    IsActive: true
                };

                (<Array<any>>(this.gvwDocuments.data)).splice(0, 0, conItem);
            }

            setRowDirtyDocument(entity) {
                if (this.gridDocumentsApi) {
                    this.gridDocumentsApi.rowEdit.setRowsDirty([entity]);
                }
            }

            DeleteDocument(entity) {
                if (this.gridDocumentsApi) {
                    this.gridDocumentsApi.rowEdit.setRowsClean(this.refundSetting.RefundSettingDocumentList);
                }

                if (entity.DDSettingDocumentId === 0) {
                    _.remove(this.gvwDocuments.data, (o) => {
                        return o === entity;
                    });
                    return;
                }
            }

            loadDocumentTypes() {

                return this.documentTypeService.GetDocumentTypesForDropdownList().query({ ownerEntityId: this.$rootScope.Entity.Id},
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            saveRefundSetting() {
                //Document Dirty Rows
                if (this.gridDocumentsApi) {
                    this.refundSetting.RefundSettingDocumentList = _.map(this.gridDocumentsApi.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.refundSetting.RefundSettingDocumentList = [];
                }

                //Consignee Dirty Rows
         
                this.refundSettingService.saveRefundSetting().save((this.refundSetting), (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);
                    this.loadRefundSetting();

                    if (this.gridDocumentsApi) {
                        this.gridDocumentsApi.rowEdit.setRowsClean(this.refundSetting.RefundSettingDocumentList);
                    }

                }, (errorResult) => {
                    this.generalService.displayMessageHandler(errorResult.data);
                });
            }


            saveCloseRefundSetting() {
                this.refundSettingService.saveRefundSetting().save(this.refundSetting, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);
                    this.$state.go("^");
                }, (errorResult) => {
                    this.generalService.displayMessageHandler(errorResult.data);
                });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("refundSettingUpdateCtrl", controllers.master.refundSettingUpdateCtrl);
    }
}